import { createContext, useContext, useEffect, useState } from "react";

const defaultSafeAreaInsets = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

interface NativeAppDataType {
  isNativeApp?: boolean;
  safeAreaInsets: typeof defaultSafeAreaInsets;
}

declare global {
  interface Window {
    isNativeApp?: boolean;
    safeAreaInsets: typeof defaultSafeAreaInsets;
  }
}

export const NativeAppContext = createContext<NativeAppDataType>({
  isNativeApp: false,
  safeAreaInsets: defaultSafeAreaInsets,
});

export function NativeAppProvider({ children }: { children: React.ReactNode }) {
  const [nativeAppData, setNativeAppData] = useState<NativeAppDataType>({
    isNativeApp: false,
    safeAreaInsets: defaultSafeAreaInsets,
  });

  // Initialize native app data
  useEffect(() => {
    const isBrowser = typeof window !== "undefined";
    const isNativeApp = isBrowser && window.isNativeApp === true;

    if (!isNativeApp) {
      return;
    }

    setNativeAppData({
      isNativeApp: true,
      safeAreaInsets: window.safeAreaInsets,
    });

    // Fix PhotoSwipe safe area insets
    const style = document.createElement("style");
    style.id = "fix-safe-area-insets";
    style.innerHTML = `
/* PhotoSwipe Fix */
.pswp__top-bar {
  top: ${window.safeAreaInsets.top}px !important;
}
    `;
    document.head.appendChild(style);

    return () => {
      const style = document.getElementById("fix-safe-area-insets");
      if (style) {
        style.remove();
      }
    };
  }, []);

  return (
    <NativeAppContext.Provider value={nativeAppData}>
      {children}
    </NativeAppContext.Provider>
  );
}

export function useNativeApp() {
  return useContext(NativeAppContext);
}

export function useAppFocus() {
  const [focused, setFocused] = useState(true);

  useEffect(() => {
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    document.addEventListener("app-active", onFocus);
    document.addEventListener("app-in-active", onBlur);

    return () => {
      document.removeEventListener("app-active", onFocus);
      document.removeEventListener("app-in-active", onBlur);
    };
  }, []);

  return focused;
}
